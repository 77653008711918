import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";
import { styled } from '@mui/material/styles';
import { CircularProgress, TextField, Button } from '@mui/material/';

import MCLogo from  "./mc_logo.png";

// const useStyles = makeStyles(theme => ({
//   margin: {
//     margin: theme.spacing(2),
//   },
//   submitBtn: {
//     margin: "2em 0 3em 0!important",
//     textTransform: "none",
//     borderRadius: "4em",
//     background: "linear-gradient(45deg, rgba(2,119,189,1) 0%, rgba(3,155,229,1) 74%, rgba(79,195,247,1) 100%)",
//     color: "white"
//   }
// }));

const FormField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(2)
}))

const LoginEmailButton = styled(Button)(({ theme }) => ({
  margin: "2em 0 3em 0!important",
  textTransform: "none",
  borderRadius: "4em",
  background: "linear-gradient(45deg, rgba(2,119,189,1) 0%, rgba(3,155,229,1) 74%, rgba(79,195,247,1) 100%)",
  color: "white"
  
}));

const LoginGoogleButton = styled(Button)(({ theme }) => ({
  margin: "2em 0 3em 0",
  textTransform: "none",
  borderRadius: "4em",
  background: "linear-gradient(45deg, rgba(2,119,189,1) 0%, rgba(3,155,229,1) 74%, rgba(79,195,247,1) 100%)",
  color: "white"
 
}));


function Login() {
  // const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      //setShowLoading(true);
      return;
    }
    if (user) navigate("/dashboard");
  }, [user, loading]);

  if (loading) {
    return (
      <div className="login">
        <CircularProgress variant="indeterminate"></CircularProgress>
        
      </div>
    )
  }

  return (
    <div className="login">
      <div className="login__container">

        <h4>MonitorCenter Admin</h4>
        <img src={MCLogo}></img>

        <FormField label="Email" name="email" type="email"
          placeholder="usuario@gmail.com" autoComplete="username"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <FormField  label="Contraseña" name="password" type="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <LoginEmailButton variant="contained" 
        onClick={() => logInWithEmailAndPassword(email, password)}
         type="submit">Iniciar sesión con email</LoginEmailButton>

        <LoginGoogleButton variant="contained" 
        onClick={signInWithGoogle}
         type="submit">Iniciar sesión con Google</LoginGoogleButton>

      </div>
    </div>
  );
}
export default Login;